import React from 'react';

export default () => (
  <svg role="img" width="32" height="32" viewBox="0 0 64 64" fill="#fff" xmlns="https://www.w3.org/2000/svg" aria-labelledby="closeIconTitle"><title id="closeIconTitle">Close icon</title>
    <path d="M29.1,32L10.6,50.6c-0.8,0.8-0.8,2.1,0,2.9c0.8,0.8,2.1,0.8,2.9,0L32,34.9l18.5,18.5 c0.8,0.8,2.1,0.8,2.9,0c0.8-0.8,0.8-2.1,0-2.9L34.9,32l18.5-18.5c0.8-0.8,0.8-2.1,0-2.9s-2.1-0.8-2.9,0L32,29.1L13.5,10.6 c-0.8-0.8-2.1-0.8-2.9,0c-0.8,0.8-0.8,2.1,0,2.9L29.1,32z"></path>
  </svg>
);






