import * as React from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@mui/material';
import { useAppContext } from '../../contexts/AppContext';
import { useTheme } from '@mui/material/styles';
import { useEditorHandlers } from './@editor';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from '../Link';
import Image, { ImageLoaderProps } from 'next/image';
import { Property } from 'csstype';
import { adsRequest } from '../../utils/ads-request';

import IconMenu from './icons/menu';
import CloseIcon from './icons/close';
import IconSearch from './icons/search';
import CnnMenuHardcoded from '../Hardcoded/CnnMenuHardcoded';

const AdvertisementSlot = dynamic<any>(
  () => import('../../components/AdvertisementSlot').then((mod) => mod.AdvertisementSlot),
  {
    ssr: false,
  }
);
interface LinkContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  isActive?: boolean;
}

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  backgroundColor: theme.theme_component_navigation_bar_background_color || theme.palette.primary.main,
  backgroundPosition: theme.theme_component_navigation_bar_background_position || 'center',
  backgroundSize: theme.theme_component_navigation_bar_background_size || 'cover',
  backgroundRepeat: theme.theme_component_navigation_bar_background_repeat || 'no-repeat',
  borderBottomWidth: theme.theme_component_navigation_bar_border_bottom_width
    ? `${theme.theme_component_navigation_bar_border_bottom_width}px`
    : 0,
  backgroundImage: theme.theme_component_navigation_bar_background_image
    ? `url(https://${theme.theme_component_navigation_bar_background_image}/)`
    : theme.theme_component_navigation_bar_background_image_gradient
    ? theme.theme_component_navigation_bar_background_image_gradient
    : 'none',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.theme_component_navigation_bar_border_bottom_color || theme.palette.divider,
  color: theme.theme_component_navigation_bar_color || theme.palette.primary.contrastText,
  outlineOffset: '-2px', // Help out cursor context editor.
  position: theme.theme_component_navigation_position as Property.Position || 'sticky',
  boxShadow: '0px 0px'
})) as typeof AppBar;

const ToolbarLeft = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignSelf: 'center',
  height: '40px',
}));

const ToolbarCenter = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-start',
  alignSelf: 'stretch',
  alignItems: 'center',
}));

const ToolbarRight = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignSelf: 'center',
}));

const HeadMenu = styled('div')(({ theme }) => ({
  display: 'flex',
  alignSelf: 'center',
  marginRight: theme.spacing(2),
}));

const MenuWrp = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
}));


const MenuContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.theme_component_footer_background_color || '#000000',
  borderTop: `1px solid ${theme.palette.primary.main}`,
  boxShadow: 'rgba(0, 0, 0, .25) 0 25px 27px -31px',
  // display: 'none',
  left: 0,
  maxHeight: 'calc(80vh - 40px)',
  overflowY: 'scroll',
  position: 'absolute',
  top: '100%',
  width: '100%',
  zIndex: 5,
}));

const LinkContainer = styled(Link)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  fontSize: '16px',
  '&:hover': {
    textDecoration: 'none'
  },
}));

const LinkContainerGame = styled(Link)<LinkContainerProps>(({ theme, isActive }) => ({
  marginLeft: theme.spacing(1),
  fontSize: '20px',
  fontWeight: `${isActive ? 700 : 'normal'}`,
  color: '#fff',
  margin: theme.spacing(0 ,2),
  '&:hover': {
    fontWeight: 700,
  },
}));

const LinkContainerGames = styled(Link)<LinkContainerProps>(({ theme, isActive }) => ({
  marginLeft: theme.spacing(1),
  fontSize: '16px',
  fontWeight: `${isActive ? 700 : 400}`,
  color: '#fff',
  margin: theme.spacing(0 ,2),
  opacity: `${isActive ? 1 : 0.6}`,
  [theme.breakpoints.down('lg')]: {
    margin: theme.spacing(0 ,1),
    fontSize: '15px',
  },
  '&:hover': {
    opacity: .8
  },
}));

const ButtonLogo = styled(Button)(({ theme }) => ({
  width: theme.theme_component_navigation_bar_logo_width || 'auto',
})) as typeof Button;

const AdvertisementSlotStyled = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  backgroundColor: theme.theme_component_navigation_bar_background_color || theme.palette.primary.main,
  borderRadius: '0px'
}));

export const NavigationBarG = () => {
  const theme = useTheme();
  const router = useRouter();
  const appContext = useAppContext();
  const { url, assetUrl, headerLogoId } = appContext.useConfig();
  const { collections } = appContext.useCollections();
  const { fullScreen } = appContext.useFullscreen();
  const basePath = router.basePath || '';

  const games = collections[0]?.games;

  const [menu, setMenu] = React.useState(false);

  const md = useMediaQuery(theme.breakpoints.up('md'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const banners = ['idhb-top-728'];

  React.useEffect(() => {
    adsRequest(banners);
  }, []);

  React.useEffect(() => {
    const { events } = router;
    const handleRouteChange = () => {
      adsRequest(banners);
    };

    events.on('routeChangeComplete', handleRouteChange);
    events.on('hashChangeComplete', handleRouteChange);
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
      events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router]);

  const onSetMenu = () => {
    setMenu(!menu);
  }

  return (
    <>
      {!fullScreen && <AdvertisementSlotStyled
        elevation={2}
        slotIdentifier="idhb-top-728"
        variant="728x112"
      />}
      <AppBarStyled className="appstyled" {...useEditorHandlers('Navigation Bar B')}>
        <Container maxWidth={false} style={{ maxWidth: '1400px' }}>
        <Toolbar disableGutters style={{ minHeight: '40px' }}>
            <ToolbarLeft>
              <MenuWrp>
                <Button onClick={onSetMenu} variant="text">
                  {menu ? <CloseIcon /> : <IconMenu />}
                </Button>
              </MenuWrp>
            {/* {!componentOptions.component_header_menu_hidden && <DrawerIconButton
                edge="start"
                size={sm ? 'medium' : 'small'}
                onClick={() => setDrawer({ ...drawer, [drawerAnchor]: true })}
                aria-label="menu"
              >
                <DrawerIcon />
              </DrawerIconButton>
            } */}
              <ButtonLogo component={Link} href="https://cnn.com/" title={url} aria-label="Home">
                <Image
                  loader={({ src }: ImageLoaderProps) => src}
                  src={`${basePath}${assetUrl}${headerLogoId}/`}
                  unoptimized
                  alt={url}
                  layout="fill"
                  objectFit="contain"
                />
              </ButtonLogo>
            </ToolbarLeft>
            <ToolbarCenter>
              <LinkContainerGame
                href={'/'}
                title={"Games"}
                underline="none"
                isActive={true}
              >
                Games
              </LinkContainerGame>
              {!mdDown && router.query.slug && games?.map((game: any) => (
                <LinkContainerGames
                  key={game.id}
                  href={`/game/${game.slug}`}
                  rel="noreferrer"
                  variant="subtitle2"
                  underline="none"
                  title={game.name}
                  isActive={router.query.slug === game.slug}
                >
                  {game.name}
                </LinkContainerGames>
              ))}
            </ToolbarCenter>

            <ToolbarRight>
              <HeadMenu>
                {md && (
                  <LinkContainer target='blank' href={'https://cnn.com/audio'} title={"Audio"} underline="hover">
                    Audio
                  </LinkContainer>
                  )}
                <LinkContainer target='blank' href={'https://cnn.com/cnn-live-restricted'} title={"Audio"} underline="hover">
                  Live TV
                </LinkContainer>
                {md && <Button onClick={onSetMenu} variant="text" sx={{ padding: 0}}>
                  <IconSearch />
                </Button>}
              </HeadMenu>
            </ToolbarRight>
          </Toolbar>
          {menu && <MenuContainer><CnnMenuHardcoded header={true} /></MenuContainer>}
        </Container>
      </AppBarStyled>
    </>
  );
};
